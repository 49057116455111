//import Navigo from 'navigo';
//import 'bumerang/validator.js';
import * as Pomocnici from 'bumerang/pomocnici.js';
import VanillaModal from 'bumerang/modal.js';
import smoothScroll from 'bumerang/scroll.js';
import scrollama from 'scrollama';
import {lory} from 'lory.js';
import defaultExport from 'wicg-focus-ring';

const modal_class = new VanillaModal({
  close : '.modal-close',
  transition: false,
  onOpen :  function(data) {
    let video_tag = document.querySelector('.modal-content video');
    let youtube_iframe = document.querySelector('.modal-content iframe');
    let modal_content = document.querySelector('.modal-content');
    modal_content.classList.remove('modal-video');
    modal_content.classList.remove('modal-youtube');
    
    if( video_tag ) {
      let koeficient_sirka = window.innerWidth > 740 ? window.innerWidth - 150 : window.innerWidth - 15;
      let koeficient_vyska = window.innerHeight > 740 ? window.innerHeight - 85 : window.innerHeight - 90;
      let novy_rozmer = Pomocnici.calculateAspectRatioFit(1920, 1080, koeficient_sirka, koeficient_vyska);
      video_tag.width = novy_rozmer.width;
      video_tag.height = novy_rozmer.height;
      video_tag.play();
      modal_content.classList.add('modal-video');
    }
    if( youtube_iframe ) {
      modal_content.classList.add('modal-youtube');
    }
  },
  onBeforeClose : function (data) {
    let video_tag = document.querySelector('.modal video');
    if(video_tag) {
      let video_banner = document.querySelector('#video-banner');
      video_banner.play();
      video_tag.pause();
    }
    if( youtube_player && typeof youtube_player.getPlayerState === 'function'/* && youtube_player.getPlayerState() == YT.PlayerState.PLAYING*/ ) {
      youtube_player.stopVideo();
      youtube_player.destroy();
    }

  }
});
var page_vars = {};
var scroll_interval_id = false;

var scroll_interval_id = setInterval(stick_menu, 10);
var youtube_player = false;
var youtube_timeout = false;
var youtube_timeout_pokus = 0;

/*
var router = new Navigo(document.querySelector('base').getAttribute('href'));
router
  .on({
    '/': function (params) {
      nacti_data('index');
    },
    '/:stranka': function (params) {
      nacti_data(params.stranka);
      if( typeof window.pageYOffset !== 'undefined' && window.pageYOffset > 400) {
        smoothScroll(document.querySelector('body'), 500, false);
      }
    }
  });

function nacti_data(stranka) {
  fetch('/api/'+stranka).then(function(response) {
    var contentType = response.headers.get("content-type");
    if(contentType && contentType.indexOf("application/json") !== -1) {
      return response.json().then(function(json) {
        // todo: přidat minimální výšku
        // todo: zprovoznit animaci
        var main = document.querySelector('main');
        main.innerHTML = json.content;
        if( typeof routuj[stranka.replace('-', '_')] !== 'undefined' ) {
          routuj[stranka.replace('-', '_')]();
        }
      });
    } else {
      console.log("Oops, we haven't got JSON!");
    }
  });
}

// přidá proklik k routeru
var menuLink = document.querySelectorAll('.route-link');
Pomocnici.forEach(menuLink, function (index, value) {
  var location = value.getAttribute('href');
  value.addEventListener('click', e => {
    e.preventDefault();
    router.navigate(location);
    if( document.querySelector('.menu-a.active') ) {
      Pomocnici.removeClass(document.querySelector('.menu-a.active'), 'active');
    }
    Pomocnici.addClass(e.target, 'active');
  });
});
*/

var zakazAnimace = document.querySelectorAll('.zakaz-animace');
Pomocnici.forEach(zakazAnimace, function (index, value) {
  Pomocnici.removeClass(value, 'zakaz-animace');
});

document.querySelector('#burger').addEventListener('click', e => {
  e.preventDefault();
  let nav = document.querySelector('.nav');

  if(Pomocnici.hasClass(document.body, 'zobraz')) {
    Pomocnici.removeClass(document.body, 'zobraz');
    nav.setAttribute('aria-hidden', true);
    nav.setAttribute('tabindex', '-1');
  } else {
    Pomocnici.addClass(document.body, 'zobraz');
    nav.setAttribute('aria-hidden', false);
    nav.removeAttribute('tabindex');
  }
  e.currentTarget.blur();
}, false);

window.addEventListener('resize', function(e) {
  /*
  if( stranka == 'index' && typeof page_vars.scrollama_casestudy !== 'undefined' ) {
    page_vars.scrollama_casestudy.resize();
    if( casestudy_zjisti_zda_skryt() ) {
      page_vars.scrollama_casestudy.disable();
      for (let elm of page_vars.elements_casestudy) {
        elm.classList.remove('skryj');
      }
    } else {
      page_vars.scrollama_casestudy.enable();
    }
  }
  */
  if( stranka == 'index' && typeof page_vars.scrollama_bloky !== 'undefined' ) {
    page_vars.scrollama_bloky.resize();
  }
  
  if( stranka == 'casestudy' && typeof page_vars.scrollama !== 'undefined' ) {
    page_vars.scrollama.resize();
  }
});

function form (form_id) {
  // spusti validaci formuláře
  let form = document.querySelector('form');
  let submit = form.querySelector('button');

  form.addEventListener('submit', function(event) {
    event.preventDefault();
    var data = new FormData(document.querySelector('form'));

    fetch('/api/submit/'+form_id, {
      method: "POST",
      body: data
    }).then(function(response) {
      var contentType = response.headers.get("content-type");
      if(contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(function(json) {
          // vypiš error nebo success
          let zprava = document.querySelector('.zprava');
          zprava.innerHTML = '<p>' + json.message + '</p>';
          if( json.success ) {
            Pomocnici.removeClass(zprava, 'error');
          } else {
            Pomocnici.addClass(zprava, 'error');
          }
          Pomocnici.removeClass(zprava, 'hide');
          smoothScroll(zprava, 500, false);
          // todo: smaž hodnoty z formuláře
        });
      } else {
        console.log("Oops, we haven't got JSON!");
      }
    });

    return false;
  });
}


function stick_menu() {
  var top = window.pageYOffset || document.documentElement.scrollTop;
  if( top > 20 ) {
    Pomocnici.addClass(document.body, 'stick-menu');
  } else {
    Pomocnici.removeClass(document.body, 'stick-menu');
  }
}

function scroll_do_bloku(entry) {
  // INFO: dát si pozor na posuvník v situaci pokud je nascrolováno na pod úroveň posledního sledovaného elementu
  page_vars.viditelny_blok_index = parseInt(entry.element.dataset.blokIndex);
  page_vars.viditelny_blok_subindex = parseInt(entry.element.dataset.blokSubindex);
  nastav_posuvnik(page_vars.viditelny_blok_index, page_vars.viditelny_blok_subindex);    

  if(page_vars.viditelny_blok_index == 3) {
    entry.element.classList.add('animuj-prijezd');
  }
}

function nastav_posuvnik(index, subindex) {
  if( typeof subindex === 'undefined' ) {
    subindex = 0;
  }

  if( index >= 3 ) {
    subindex = 4;
  }

  // todo: není potřeba vykonávat při každé scrollu
  for (let elm of page_vars.elements_posuvnik_div) {
    if ( parseInt(elm.dataset.blokIndex) <= index && typeof elm.dataset.blokSubindex !== 'undefined' && parseInt(elm.dataset.blokSubindex) <= subindex) {
      elm.classList.add('active');
    } else if( parseInt(elm.dataset.blokIndex) <= index && typeof elm.dataset.blokSubindex === 'undefined') {
      elm.classList.add('active');
    } else {
      elm.classList.remove('active');
    }
  }
}

function klik_na_video_odkaz() {
  let elements_video_link = document.querySelectorAll('.js_youtube_modal');

  for (let elm of elements_video_link) {
    elm.addEventListener('click', klik_na_video_odkaz_event);
  }
}

function klik_na_video_odkaz_event(event) {
  event.preventDefault();
  if (event.currentTarget.dataset.videoId) {  
    // vzhledem k tomu že knihovna pro YouTube se nahravá asynchronně, tak zkusíme počkat 1sekundu než se nahraje
    if(typeof YT !== 'undefined') {
      spusti_youtube(event.currentTarget.dataset.videoId);
      window.clearInterval(youtube_timeout);
    } else {
      youtube_timeout_pokus = 0;
      youtube_timeout = window.setInterval(function(videoId){
        if( typeof YT !== 'undefined' ) {
          window.clearInterval(youtube_timeout);
          spusti_youtube();
        }
        if( youtube_timeout > 10 ) {
          window.clearInterval(youtube_timeout);
          alert('ERROR: Nepodařilo se načíst video. Prosíme obnovte stránku a opakujte poslední akci.');
        }
        youtube_timeout++;
      }, 100, event.currentTarget.dataset.videoId);
    }
    
  } else {
    alert('ERROR: Nepodařilo se načíst YouTube video.');
  }
}

function spusti_youtube(videoId) {
  let koeficient_sirka = window.innerWidth > 740 ? window.innerWidth - 150 : window.innerWidth - 15;
  let koeficient_vyska = window.innerHeight > 740 ? window.innerHeight - 85 : window.innerHeight - 90;
  let novy_rozmer = Pomocnici.calculateAspectRatioFit(720, 405, koeficient_sirka, koeficient_vyska);

  youtube_player = new YT.Player('youtube-div', {
    width: novy_rozmer.width,
    height: novy_rozmer.height,
    videoId: videoId,
    //host: homepage_url,
    events: {
      'onReady': onPlayerReady,
      //'onStateChange': onPlayerStateChange
    }
  });

  modal_class.open('#video-modal');
}

function onPlayerReady(event) {
  event.target.playVideo();
}

function onPlayerStateChange(event) {
  /*
  if (event.data == YT.PlayerState.PLAYING && !done) {
    setTimeout(stopVideo, 6000);
    done = true;
  }
  */
}

function slider_event(event) {
  let key = event.currentTarget.dataset.sliderKey;
  if (event.type === 'before.lory.init') {
    // spočítá šířku slajdů
    event.currentTarget.querySelector('.js_slides').style.width = parseFloat(100 * (page_vars.slider[key].slide_count + 4)).toFixed(2) + '%';
    let width = parseFloat(100 / (page_vars.slider[key].slide_count + 4)).toFixed(4);
    for (let slide of page_vars.slider[key].slide) {
      slide.style.width = width + '%';
    }
  }
  if (event.type === 'after.lory.init') {
    for (let slide of event.currentTarget.querySelectorAll('.dots li')) {
      slide.addEventListener('click', function(event) {
        let key = event.currentTarget.dataset.sliderKey;
        page_vars.slider[key].slider_object.slideTo(parseInt(event.currentTarget.dataset.index));
      });
    }
  }
  if (event.type === 'after.lory.slide') {
    if(page_vars.slider[key].dot_container) {
      event.currentTarget.querySelector('.js_dots .active').classList.remove('active');
      /*
      if(typeof page_vars.slider[key].slide_current !== 'undefined') {
        page_vars.slider[key].slide_current.innerHTML = event.detail.currentSlide ;
      }
      */
      if( page_vars.slider[key].dot_container ) {
        page_vars.slider[key].dot_container.childNodes[event.detail.currentSlide].classList.add('active');
      }
    }
    
    if( event.currentTarget.classList.contains('js_reziseri') ) {
      let slide_index = event.detail.currentSlide - 2 <= page_vars.slider[key].slide_count ? event.detail.currentSlide - 2 : 0;
      if( typeof page_vars.slider[key].slide[slide_index] !== 'undefined' ) {
        if( page_vars.slider[key].slide[slide_index].dataset.dalsiReziser ) {
          page_vars.slider[key].slider_element.querySelector('.js_next span').innerHTML = page_vars.slider[key].slide[slide_index].dataset.dalsiReziser;
        }
        if( page_vars.slider[key].slide[slide_index].dataset.predchoziReziser ) {
          page_vars.slider[key].slider_element.querySelector('.js_prev span').innerHTML = page_vars.slider[key].slide[slide_index].dataset.predchoziReziser;
        }
      }
      if( window.innerHeight < event.currentTarget.offsetHeight ) {
        smoothScroll(document.querySelector('.reziseri'), 300, false);
      }
    }
  }
  if (event.type === 'on.lory.resize' && page_vars.slider[key].dot_container) {
    event.currentTarget.querySelector('.js_dots .active').classList.remove('active');
    if( page_vars.slider[key].dot_container ) {
      page_vars.slider[key].dot_container.childNodes[0].classList.add('active');
    }
  }
}

function refernce_slider_event(event) {
  let key = event.currentTarget.dataset.sliderKey;
  if (event.type === 'before.lory.init') {
    event.currentTarget.querySelector('.js_slides').style.width = parseFloat(100 * (page_vars.slider[key].slide_count + 4)).toFixed(2) + '%';
  }  
}
function init_slider() {
  // slider
  let sliders = document.querySelectorAll('.js_slider');
  page_vars.slider = [];
  if( sliders.length ) {
    for (let slider of sliders) {
      let key = slider.dataset.sliderKey;

      page_vars.slider[key] = [];
      page_vars.slider[key].slider_element = slider;
      page_vars.slider[key].slide = slider.querySelectorAll('.js_slide');
      page_vars.slider[key].slide_count = page_vars.slider[key].slide.length;
      page_vars.slider[key].dot_container = slider.querySelector('.js_dots');
      //page_vars.slider[key].slide_current = document.querySelector('.cs-slider-current');
      
      page_vars.slider[key].slider_element.addEventListener('before.lory.init', slider_event);
      page_vars.slider[key].slider_element.addEventListener('after.lory.init', slider_event);
      page_vars.slider[key].slider_element.addEventListener('after.lory.slide', slider_event);
      page_vars.slider[key].slider_element.addEventListener('on.lory.resize', slider_event);

      page_vars.slider[key].slider_object = lory(slider, {
        infinite: 2,
        enableMouseEvents: true
      });
    }
  }
}

function casestudy_posun_progress(response) {
  let aktualni_casestudy = 1;
  page_vars.viditelny_blok_index = 2;
  for (let elm of page_vars.elements_casestudy) {
    if( !casestudy_zjisti_zda_skryt() ) {
      var elementPos = elm.getBoundingClientRect();
      if(elementPos.bottom <= window.innerHeight) {
        if( elm.dataset.blokSubindex < 4 && !elm.classList.contains('skryj') ) {
          elm.classList.add('skryj');
        }
        aktualni_casestudy = elm.dataset.blokSubindex;
        if( elm.dataset.blokSubindex == 4 ) {
          for (let elm2 of page_vars.elements_casestudy) {
            elm2.classList.remove('skryj');
          }
        }
      } else {
        elm.classList.remove('skryj');
      }
    } else {
      elm.classList.remove('skryj');
    }
  }
}

function casestudy_zjisti_zda_skryt() {
  return window.innerWidth < 800 || window.innerHeight < 450;
}

function citace_slider_resize() {
  let key = 1;
  if( window.innerWidth <= 600 && typeof page_vars.slider[key].slider_object !== 'object') {
    page_vars.slider[key].slider_object = lory(page_vars.slider[key].slider_element, {
      infinite: 0,
      enableMouseEvents: true
    });
  } else if(window.innerWidth > 600 && typeof page_vars.slider[key].slider_object === 'object') {
    page_vars.slider[key].slider_object.destroy();
    page_vars.slider[key].slider_object = false;
    page_vars.slider[key].slider_element.querySelector('.js_slides').removeAttribute('style');
    for (let slide of page_vars.slider[key].slide) {
      slide.removeAttribute('style');
    }
  }
}

// router
var routuj = {
  index: function () {  
    page_vars.elements_posuvnik_div = document.querySelectorAll('.posuvnik-div');

    // všechny čtyři hlavní bloky
    page_vars.elements_bloky = document.querySelectorAll('.js_observe');
    page_vars.scrollama_bloky = scrollama();
    page_vars.scrollama_bloky
      .setup({
        step: '.js_observe',
        offset: 0.5,
        debug: false,
      })
      .onStepEnter(scroll_do_bloku);

    // klik na casestudy
    let elements_casestudy_box = document.querySelectorAll('.reference__blok');

    for (let elm of elements_casestudy_box) {
      console.log(elm);
      elm.addEventListener('click', function(event) {            
        console.log("click");
        klik_na_video_odkaz_event(event);
        return false;        
      });
    }
    
    // klik na tlačítko posuvníku
    let elements_posuvnik = document.querySelectorAll('.posuvnik .button');

    for (let elm of elements_posuvnik) {
      elm.addEventListener('click', function(event) {
        smoothScroll(document.querySelector('.'+event.currentTarget.dataset.scrollto), 700, false);
      });
    }

    
    let video_banner = document.querySelector('#video-banner');
    //let video_banner_source = video_banner.querySelector('source');
    if( window.innerWidth < 900 ) {
      video_banner.setAttribute('src', '/video/showreel_480.mp4');
    } else if( window.innerWidth < 400 ) {
      video_banner.setAttribute('src', '/video/showreel_270.mp4');
    } 
    video_banner.play();
    
    //todo: spusti animaci banneru až video začne hrát
    //document.querySelector('.banner-svg').classList.add('animuj'); 
        
    // stustí přehrávání videa
    function spusti_video(event) {
      modal_class.open('#video-banner-modal');
      page_vars.video_banner = document.querySelector('#video-banner');
      page_vars.video_banner.pause();
    };
    
    document.querySelector('.banner-video').addEventListener('click', spusti_video);
    document.querySelector('.banner-box').addEventListener('click', spusti_video);
    
    // slider
    let sliders = document.querySelectorAll('.js_citace');
    page_vars.slider = [];
    if( sliders.length ) {
      for (let slider of sliders) {
        let key = slider.dataset.sliderKey;

        page_vars.slider[key] = [];
        page_vars.slider[key].slider_object = false;
        page_vars.slider[key].slider_element = slider;
        page_vars.slider[key].slide = slider.querySelectorAll('.js_slide');
        page_vars.slider[key].slide_count = page_vars.slider[key].slide.length;
        page_vars.slider[key].dot_container = slider.querySelector('.js_dots');
        
        page_vars.slider[key].slider_element.addEventListener('before.lory.init', slider_event);
        page_vars.slider[key].slider_element.addEventListener('after.lory.init', slider_event);
        page_vars.slider[key].slider_element.addEventListener('after.lory.slide', slider_event);
        page_vars.slider[key].slider_element.addEventListener('on.lory.resize', slider_event);
      }
      citace_slider_resize();
      window.addEventListener('resize', citace_slider_resize);
    }
    
    klik_na_video_odkaz();
    
  },
  reference: function () {
    klik_na_video_odkaz();
  },
  
  casestudy: function () {
    init_slider();
    klik_na_video_odkaz();
  
    // animace pro case study
    if(window.innerWidth > 800) {
      let elements_animace = document.querySelectorAll('.cs-box-img-box');  
      for (let element of elements_animace) {
        if( !Pomocnici.inViewport(element) ) {
          element.classList.add('animuj');
        }
      }
      
      page_vars.scrollama = scrollama();
      page_vars.scrollama
        .setup({
          step: '.cs-box-img-box.animuj',
          offset: 0.65, 
          once: true,
        })
        .onStepEnter(function(entry) {
          entry.element.classList.remove('animuj');
        }); 
    }
  },
  
  tym: function () {
    init_slider();
    klik_na_video_odkaz();
    
    // simuluj hover u profilových obrázků (řešení pro dotykové mobily a safari)
    let profil_links = document.querySelectorAll('.profil-link');
    for (let elm of profil_links) {
      elm.addEventListener('click', function(event) {
        if( event.currentTarget.classList.contains('hover') ) {
          event.currentTarget.classList.remove('hover');
          event.currentTarget.blur();
        } else {
          event.currentTarget.classList.add('hover');
        }
      });
    }
  },
  
  jak_pracujeme: function () {

    // klik na na butony nad timeline
    page_vars.elements_prepinac_button = document.querySelectorAll('.prepinac-button');

    for (let elm of page_vars.elements_prepinac_button) {
      elm.addEventListener('click', function(event) {
        let curreent_tab_id = event.currentTarget.getAttribute('aria-controls');
        
        for (let elm of page_vars.elements_prepinac_button) {
          let tab_id = elm.getAttribute('aria-controls');
          let tab = document.querySelector('#'+tab_id);
          if( curreent_tab_id == tab_id ) {
            elm.classList.add('active');
            elm.setAttribute('aria-selected', 'true');
            tab.classList.add('active');
            tab.setAttribute('aria-hidden', 'false');
            tab.setAttribute('hidden', false);            
          } else {
            elm.classList.remove('active');
            elm.setAttribute('aria-selected', 'false');
            tab.classList.remove('active');
            tab.setAttribute('aria-hidden', 'true');
            tab.setAttribute('hidden', true);            
          }

        }
      });
    } 
      
    page_vars.scrollama = scrollama();
    page_vars.scrollama
      .setup({
        step: '.trojuhelnik',
        offset: 0.2,
      })
      .onStepEnter(function(data) {
        data.element.classList.add('animuj');
        //page_vars.scrollama.disable();
      }).onStepExit(function(data) {
        data.element.classList.remove('animuj');
      }); 
      
    // simuluj hover u profilových obrázků (řešení pro dotykové mobily a safari)
    let timeline_boxs = document.querySelectorAll('.timeline-box');
    for (let elm of timeline_boxs) {
      elm.addEventListener('click', function(event) {
        if( event.currentTarget.classList.contains('hover') ) {
          event.currentTarget.classList.remove('hover');
          event.currentTarget.blur();
        } else {
          event.currentTarget.classList.add('hover');
        }
      });
    }      
         
  }
};

if( typeof routuj[stranka] !== 'undefined' ) {
  routuj[stranka]();
}

/*
function zobraz_pruh_prekryti(event) {
  if(window.pageYOffset > page_vars.elements_casestudy_prekryti[0].offsetHeight && page_vars.elements_casestudy[3].getBoundingClientRect().top > 0 ) {
    page_vars.elements_casestudy_prekryti[0].style.display = 'block';
  } else {
    page_vars.elements_casestudy_prekryti[0].style.display = 'none';
  }
}
*/
