var smoothScroll = function() {};
// Feature Test
if ( 'querySelector' in document && 'addEventListener' in window && Array.prototype.forEach ) {

    // Function to animate the scroll
    smoothScroll = function (anchor, duration, hash) {
        var posunoutDolu = 0;
        if( stranka == 'index' ) {
          var anchor_id = anchor.getAttribute('id');
          if(anchor_id.indexOf('index-casestudy') !== -1) {
            var casestudy_wrap = document.querySelector('.casestudies-wrap');
            if( anchor_id == 'index-casestudy-1' ) {
              posunoutDolu = document.querySelector('.index-casestudy-1').offsetHeight;
            }
            if( anchor_id == 'index-casestudy-2' ) {
              posunoutDolu = document.querySelector('.index-casestudy-1').offsetHeight * 2;
            }
            if( anchor_id == 'index-casestudy-3' ) {
              posunoutDolu = casestudy_wrap.offsetHeight;
            }
            anchor = casestudy_wrap;            
          }
        }
        // Calculate how far and how fast to scroll
        var startLocation = window.pageYOffset;
        
        var endLocation = anchor.offsetTop;
        if( stranka != 'index' ) {
          endLocation = Math.max(0, endLocation - 35);
        }
        endLocation = endLocation + posunoutDolu;
        
        var distance = endLocation - startLocation;
        var increments = distance/(duration/16);
        var stopAnimation;
        var set_hash = hash;

        // Scroll the page by an increment, and check if it's time to stop
        var animateScroll = function (hash) {
            window.scrollBy(0, increments);
            stopAnimation();
        };

        // If scrolling down
        if ( increments >= 0 ) {
            // Stop animation when you reach the anchor OR the bottom of the page
            stopAnimation = function () {
                var travelled = window.pageYOffset;
                if ( (travelled >= (endLocation - increments)) || ((window.innerHeight + travelled) >= document.body.offsetHeight) ) {
                    clearInterval(runAnimation);
                    if(set_hash) {
                      window.location.hash = set_hash;
                    }
                }
            };
        }
        // If scrolling up
        else {
            // Stop animation when you reach the anchor OR the top of the page
            stopAnimation = function () {
                var travelled = window.pageYOffset;
                if ( travelled <= (endLocation || 0) ) {
                    clearInterval(runAnimation);
                    if(set_hash) {
                      window.location.hash = set_hash;
                    }
                }
            };
        }

        // Loop the animation function
        var runAnimation = setInterval(animateScroll, 16);

    };

    // Define smooth scroll links
    var scrollToggle = document.querySelectorAll('.scroll');

    // For each smooth scroll link
    [].forEach.call(scrollToggle, function (toggle) {

        // When the smooth scroll link is clicked
        toggle.addEventListener('click', function(e) {

            // Prevent the default link behavior
            e.preventDefault();

            // Get anchor link and calculate distance from the top
            var dataID = toggle.getAttribute('href');
            var dataTarget = document.querySelector(dataID);
            var dataSpeed = toggle.getAttribute('data-speed');

            // If the anchor exists
            if (dataTarget) {
                // Scroll to the anchor
                smoothScroll(dataTarget, dataSpeed || 500, false);
            }

        }, false);

    });
}

export default smoothScroll;
